<Form [formGroup]="replenishmentResourceForm">
  <div class="contenedor" [ngStyle]="{'grid-template-rows':withObservations?'1fr 1fr':'1fr'}">
    <div class="box1">
      <div>
        <span class="headline-m-govco">Recurso de reposición y apelación</span>
        <div (dblclick)="verDocumento()" class="mt-3"
          style="cursor: pointer;display: grid; grid-template-columns: 20% 80%;background-color: #F4F4F4;width: 70%;padding-bottom: 15px; padding-top: 5px;">
          <div>
            <i class="bi bi-file-earmark-pdf icon"></i>
          </div>
          <div class="ml-3">
            <span>Recurso de reposición y apelación</span>
          </div>
        </div>
      </div>
      <div>
        <span class="headline-m-govco">Respuesta a recurso</span>
        
        <div class="mt-3" style="display: grid;grid-template-columns: 60% 40%;">
          <input (change)="changeDocument(replenishmentDocument)" formControlName="fileR" accept=".pdf" (change)="changeDocument($event)" style="height: 50px;width: 90%;"
            class="form-control gov-co-form-control" id="formFileLg" type="file" #replenishmentDocument>
          <div (dblclick)="seeDocument(replenishmentDocument)"
            style="display: grid; grid-template-columns: 20% 50% 30%;background-color: #F4F4F4;padding-bottom: 15px; padding-top: 5px;">
            <div>
              <i class="bi bi-file-earmark-pdf icon"></i>
            </div>
            <div style="cursor: pointer;" class="ml-3">
              <span class="d-block">{{replenishmentDocument.files.length==0?'no
                file':replenishmentDocument.files[0].name}}</span>
              <span class="d-block">{{replenishmentDocument.files.length==0?'0
                Kb':replenishmentDocument.files[0].size/1000 +' Kb'}}</span>
            </div>
            <div (click)="replenishmentDocument.value = null; replenishmentResourceForm.get('fileR').setValue('')">
              <i class="bi bi-trash3-fill icon"></i>
            </div>
          </div>
        </div>

        <div *ngIf="RecursoFlag" (dblclick)="verDocumento2()" class="mt-3 mb-5"
          style="cursor: pointer;display: grid; grid-template-columns: 20% 80%;background-color: #F4F4F4;width: 70%;padding-bottom: 15px; padding-top: 5px;">
          <div>
            <i class="bi bi-file-earmark-pdf icon"></i>
          </div>
          <div class="ml-3">
            <span>Respuesta a recurso cargada por funcionario anteior</span>
          </div>
        </div>
      </div>
    </div>

    
      <div *ngIf="withObservations" [ngClass]="{'box2': !validationResultsFlag, 'box22' : validationResultsFlag }">
      <div class="form-group gov-co-form-group mt-3" *ngIf="validationResultsFlag">
        <span class="headline-m-govco">Resultado de la validación</span>
          <select formControlName="resultadoValidacion" style="width: 80%;" class="selectpicker form-control" id="usuarioAsignado">
            <option hidden value="">Seleccionar</option>
            <option *ngFor="let validate of validationResults" value="{{validate.idParametroDetalle}}">{{validate.vcNombre}}</option>
          </select>
      </div>
      <div>
        <div class="form-group gov-co-form-group">
          <span class="headline-m-govco">Observaciones</span>
          <textarea formControlName="observaciones" class="form-control" rows="5"></textarea>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center mt-5">
          <div data-toggle="modal" data-target="#mdConfirmation" class="text-center mx-5 seguimiento">
            <div style="border: 0.5px solid black; padding: 0px 30px;">
              <i class="bi bi-eye-fill" style="font-size: 35px; color: #3366cc;"></i>
            </div>
              <span style="font-size: 10px;" class="headline-xs-govco" data-toggle="modal" data-target="#mdConfirmation">Seguimiento y auditoría</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</Form> 


<div class="documentViewer">
  <app-document-viewer [isVisible]="viewDocument2" [document]="urlSafeDocument2"
    (closeButtonEvent)="closeDocument()"></app-document-viewer>
</div>

<div class="documentViewer">
  <app-document-viewer [isVisible]="viewDocument" [document]="urlSafeDocument"
    (closeButtonEvent)="closeDocument()"></app-document-viewer>
</div>


<div class="modal fade" id="mdConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content seguimiento">
          <div class="modal-header seguimientoTitulo">
              <h5 class="modal-title text-white font-weight-bold">Seguimiento y Auditoria</h5>
          </div>
          <div class="modal-body">
              <p class="text-table">Tabla de segumiento</p>
              <table class="table mt-2 table-bordered">
                  <thead>
                      <tr>
                          <th scope="col">Fecha de <br /> seguimiento</th>
                          <th scope="col">Usuario</th>
                          <th scope="col">Estado</th>
                          <th scope="col">Observacion</th>
                      </tr>
                  </thead>
                  <tbody *ngFor="let a of auditoria">
                      <tr *ngIf="a.blSeguimientoDefinitivo">
                        <th scope="row">{{a.dtFechaObservacion}}</th>
                        <td>{{a.vcNombreUsuario}}</td>
                        <td>{{a.vcEstado}}</td>
                        <td>{{a.vcObservacion}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>

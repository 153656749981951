import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const { PROCEDURE_FILE_MANAGER_URI } = environment;

@Component({
  selector: 'app-replenishment-resource',
  templateUrl: './replenishment-resource.component.html',
  styleUrls: ['./replenishment-resource.component.scss']
})
export class ReplenishmentResourceComponent {

  urlSafeDocument: SafeResourceUrl;

  urlSafeDocument2: SafeResourceUrl;

  @Input() validationResults: any;

  @Input() validationResultsFlag: boolean = false;

  @Input() RecursoFlag: boolean = false;

  /**
   * Ver documento
   */
  viewDocument:boolean = false;

  viewDocument2:boolean = false;

  public replenishmentResourceForm: any;

  @Input() auditoria: any;

  @Input() withObservations: boolean;

  @Input() radicado: string = "";

  @Input() documentosRecursoReposicion: any[] = [];

  @Output() replenishmentDocumentEvent = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer, private controlContainer: ControlContainer,){

  }

  ngOnInit() {
    console.log("En este instante el componente ha cargado");
    console.log("estos son los documentos de reposición");

    console.log(this.documentosRecursoReposicion)

    this.replenishmentResourceForm = this.controlContainer.control;
    this.replenishmentResourceForm = this.replenishmentResourceForm.controls['replenishmentResourceForm'];
  }


  onChange = (e:any) => {
    console.log(e);
    this.replenishmentDocumentEvent.emit(e);
  }

  public verDocumento(){
    const contenedor = "manipalimentos";

    this.viewDocument2 = true;
    this.urlSafeDocument2 = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${this.radicado}/${this.documentosRecursoReposicion[0].vcPath}`);
  }

  public verDocumento2(){
    const contenedor = "manipalimentos";

    this.viewDocument2 = true;
    this.urlSafeDocument2 = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${this.radicado}/${this.documentosRecursoReposicion[1].vcPath}`);
  }

  changeDocument= (e:any) =>{

    this.RecursoFlag = false;

    console.log(e.files[0]);

    if(e.files.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Sin archivo',
        text: 'No se ha cargado un archivo',
      })

      this.replenishmentDocumentEvent.emit(null);
    }else{
      if (e.files[0].type != 'application/pdf') {

        Swal.fire({
          icon: 'error',
          title: 'Tipo de archivo incorrecto',
          text: 'El archivo cargado no es un pdf',
        })

        this.replenishmentDocumentEvent.emit(null);

      } else {
        console.log(e.files[0]);
        this.replenishmentDocumentEvent.emit(e.files[0]);
      }
    }

  }

  seeDocument = (e:any) => {

    if(e.files.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Sin archivo',
        text: 'No se ha cargado un archivo',
      })
    }else{
      if (e.files[0].type != 'application/pdf') {

        Swal.fire({
          icon: 'error',
          title: 'Tipo de archivo incorrecto',
          text: 'El archivo cargado no es un pdf',
        })

      } else {
        var reader = new FileReader();
      reader.readAsDataURL(e.files[0]);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        const documentData = base64data;
        this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(documentData);
      };
      this.viewDocument = true;
      }
    }
  }

  closeDocument = () => {
    this.viewDocument = false;
    this.viewDocument2 = false;
  }

}

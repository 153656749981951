<nz-spin style="display: block;width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'"
         nzTip="Se estan cargando los datos, por favor espere un momento....">
  <h4 class="headline-l-govco mb-4 titulo">Autorización para capacitadores de manipulación de alimentos</h4>
  <app-advance-line [width]="60" [step]="2" [currentProgress]="70" [options]="advanceLineData"></app-advance-line>
  <form [formGroup]="coordinatorForm">
    <div class="container">
      <div class="accordion-govco" id="accordion">
        <div class="card border border-0">
          <div class="card-header" id="informacion-solicitud-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#informacion-solicitud"
                 aria-expanded="false" aria-controls="informacion-solicitud">
                <span class="headline-m-govco">Información de la solicitud</span> <span
                class="govco-icon govco-icon-dropdown-v2"></span>
              </a>
            </h2>
          </div>
          <div id="informacion-solicitud" class="collapse" aria-labelledby="informacion-solicitud-header"
               data-parent="#accordion">
            <div class="card-body">
              <app-application-information></app-application-information>
            </div>
          </div>
        </div>
        <div class="card border border-0">
          <div class="card-header" id="informacion-representante-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#informacion-representante"
                 aria-expanded="false" aria-controls="informacion-representante">
                <span
                  class="headline-m-govco">{{ isNatural ? 'Datos básicos' : 'Datos del Representante Legal' }}</span>
                <span class="govco-icon govco-icon-dropdown-v2"></span>
              </a>
            </h2>
          </div>
          <div id="informacion-representante" class="collapse" aria-labelledby="informacion-representante-header"
               data-parent="#accordion">
            <div class="card-body">
              <app-basic-data2 [documentTypes]="documentTypes" [mostrarDireccion]="mostrarDireccion"></app-basic-data2>
            </div>
          </div>
        </div>
        <div *ngIf="isNatural" class="card border border-0">
          <div class="card-header" id="datos-solicitud-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#datos-solicitud"
                 aria-expanded="false" aria-controls="datos-solicitud"><span class="headline-m-govco">Datos de la
                  solicitud</span><span class="govco-icon govco-icon-dropdown-v2"></span></a>
            </h2>
          </div>
          <div id="datos-solicitud" class="collapse" aria-labelledby="datos-solicitud-header" data-parent="#accordion">
            <div class="card-body">
              <app-application-data [trainingDataTypes]="trainingDataTypes"
                                    (trainingDataTypesSelectedEvent)="addSelectedTraining($event)"></app-application-data>
            </div>
          </div>
        </div>
        <div *ngIf="isNatural && mostrar" class="card border border-0">
          <div class="card-header" id="archivos-adjuntos-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#archivos-adjuntos"
                 aria-expanded="false" aria-controls="archivos-adjuntos"><span class="headline-m-govco">Archivos
                  adjuntos</span> <span class="govco-icon govco-icon-dropdown-v2"></span></a>
            </h2>
          </div>
          <div id="archivos-adjuntos" class="collapse" aria-labelledby="archivos-adjuntos-header"
               data-parent="#accordion">
            <div class="card-body">
              <app-attached-files [documentation]="documentation"
                                  (approvedDocumentationEvent)="addApprovedDocumentation($event)"></app-attached-files>
            </div>
          </div>
        </div>
        <div *ngIf="!isNatural && mostrar"  class="card border border-0">
          <div class="card-header" id="informacion-capacitadores-header">
            <h2 class="clearfix mb-0">
              <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#informacion-capacitadores"
                 aria-expanded="false" aria-controls="informacion-capacitadores">
                <span class="headline-m-govco">Listado de capacitadores</span> <span
                class="govco-icon govco-icon-dropdown-v2"></span>
              </a>
            </h2>
          </div>
          <div id="informacion-capacitadores" class="collapse" aria-labelledby="informacion-capacitadores-header"
               data-parent="#accordion">
            <div class="card-body" style="margin-left: -25px; margin-top: -20px; width: 1807px;">
              <app-list-of-trainers [trainers]="trainers" (approvedTrainerEvent)="approvedTrainer($event)"
                                    (viewDetailEvent)="viewDetailTrainer($event)"></app-list-of-trainers>
              <div *ngIf="!canBeApprovedFlag" class="alert alert-light notaDocumentos" style="width: 65%; padding: 0px;">
                Recuerde que para indicar que un capacitador cumple, se debe previamente haber aprobado todos los documentos que este capacitador tiene en su registro.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isResourceReview" class="row mt-4">
        <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3 col-xs-12">
          <label class="title">Resultado validación</label>
          <div class="form-group">
            <select class="form-control" formControlName="selectanswer">
              <option value="Select" selected>Seleccionar</option>
              <option value="true">Aprobado por Coordinador</option>
              <option value="false">Devolver solicitud</option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-md-6 col-xl-6 col-xs-12">
          <label class="titleObservation">Observación</label>
          <textarea rows="5" class="form-control" formControlName="observation" #observationTextarea></textarea>
        </div>
        <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3 col-xs-12">
          <div class="auditory bordered d-flex justify-content-center align-items-center mt-2">
            <div class="custom-icon mt-5">
              <i (click)="audit()" data-toggle="modal" data-target="#exampleModal" class="fa-solid fa-eye"
                 style="font-size: 40px; color:#3366cc;cursor:pointer;"></i>
              <p class="text_auditory">Seguimiento y auditoria</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isResourceReview" class="card border border-0 mt-4">
        <app-replenishment-resource [RecursoFlag]="true" [validationResultsFlag]="true" [validationResults]="validationResults" (replenishmentDocumentEvent)="changeDocument($event)" [radicado]="this.fileNumber" [documentosRecursoReposicion]="documentosRecursoReposicion" [auditoria]="auditlist" [withObservations]="true"></app-replenishment-resource>
      </div>

      <div *ngIf="!isResourceReview" class="row mt-5">
        <div class="col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-primary" [disabled]="!textareaValue && updateDocumentation" (click)="save()">Guardar</button>
          <button class="btn btn-outline-primary ml-3" [disabled]="buttonDisabled" (click)="view()">Ver documento
          </button>
          <button class="btn btn-primary ml-3" (click)="sendReview()">Enviar</button>
        </div>
      </div>

      <div *ngIf="isResourceReview" class="row mt-5">
        <div class="col-12 d-flex justify-content-center mt-4">
          <button class="btn btn-primary ml-3" (click)="send2()">Enviar</button>
        </div>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content seguimiento">
            <div class="modal-header header seguimientoTitulo">
              <h5 class="modal-title" id="exampleModalLabel">Seguimiento y auditoria</h5>
              <button type="button" style="color:#fff !important;" class="close" data-dismiss="modal"
                      aria-label="Close">
                <span aria-hidden="true" id="icon-w">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style="font-size: 25px; font-weight: 500;">Tabla de seguimiento</p>
              <table class="table  table-bordered">
                <thead>
                <tr>
                  <th>Fecha de seguimiento</th>
                  <th>Usuario</th>
                  <th>Estado</th>
                  <th>Observación</th>
                </tr>
                </thead>
                <tbody *ngFor="let item of auditlist">
                <tr *ngIf="item.blSeguimientoDefinitivo">
                  <td>{{ item.dtFechaObservacion | date:'dd/MM/yyyy'}}</td>
                  <td>{{ item.vcNombreUsuario }}</td>
                  <td>{{ item.vcEstado }}</td>
                  <td>{{ item.vcObservacion }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="documentViewer">
        <app-document-viewer [title]="documentVisualizationTitle" [isLoadingDocument]="isLoadingDocument" [isVisible]="showModal" [document]="urlSafeDocument"
                             (closeButtonEvent)="closeDocument()"></app-document-viewer>
      </div>
      <div class="modals">
        <app-modals [isLoading]="isLoadingTrainer"
                    (approvedDocumentationEvent)="addApprovedDocumentationTrainer($event)"
                    [documentation]="detailDocumentation"
                    (trainingDataTypesSelectedEvent)="addModalSelectedTraining($event)"
                    [trainingDataTypes]="detailTrainingDataTypes" [documentTypes]="documentTypes22"
                    [isVisible]="viewTrainerDetailModal" [type]="'trainerInformation'"
                    (closeButtonEvent)="closeDocument()"
                    (acceptButtonTrainerEvent)="acceptSelectedTrainer($event)">
        </app-modals>
      </div>
    </div>
  </form>
</nz-spin>

<div class="row">
  <div class="col-sm-12 col-xs-12 col-xl-12 col-md-12 col-lg-12">
      <p class="title_section">Datos básicos</p>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-xs-12 col-xl-8 col-md-8 col-lg-8">
    <form [formGroup]="formRequest">
      <div class="solicitante-natural" *ngIf="mostrarAlgoPN">
          <div class="form-row">
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="primerNombre">Primer Nombre </label>
                  <input formControlName="primerNombre" type="text" id="primerNombre" class="form-control gov-co-form-control" readonly />
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="segundoNombre">Segundo Nombre</label>
                  <input formControlName="segundoNombre" type="text" id="segundoNombre" class="form-control gov-co-form-control" readonly />
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="primerApellido">Primer Apellido </label>
                  <input formControlName="primerApellido" type="text" id="primerApellido" class="form-control gov-co-form-control" readonly />
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="segundoApellido">Segundo Apellido</label>
                  <input formControlName="segundoApellido" type="text" id="segundoApellido" class="form-control gov-co-form-control" readonly />
              </div>
          </div>
          <div class="form-row mt-4">
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="tipoDocumento">Tipo de Identificación</label>
                  <select formControlName="tipoDocumento" class="form-control" >
                    <option *ngFor="let opcion of documentTypes" [value]="opcion.idTipoIdentificacion">{{ opcion.descripcion }}</option>
                  </select>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="numeroDocumento">Número de Identificación </label>
                  <input formControlName="numeroDocumento" type="text" id="numeroDocumento" class="form-control gov-co-form-control" readonly />
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="numeroContacto">Teléfono de contacto</label>
                  <input formControlName="numeroContactoN" type="text" id="numeroContacto" class="form-control gov-co-form-control" readonly />
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="correoElectronico">Correo electrónico</label>
                  <input formControlName="correoElectronicoN" type="text" id="correoElectronico" class="form-control gov-co-form-control" readonly />
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-10 col-md-10 col-xl-10">
                  <label for="address">Dirección</label>
                  <input formControlName="address" type="text" id="address" class="form-control gov-co-form-control address" readonly />
              </div>
          </div>
          <div class="form-row mt-4">
              <div class="form-group col-sm-12 col-xs-12 col-lg-10 col-md-10 col-xl-10">
                  <p class="title_section">Tipos de capacitador</p>
                  <div class="d-inline-block mr-3" *ngFor="let opcion of checkcapacitation">
                    <label>
                      <input (click)="capacitacion(opcion)" type="checkbox" name="capacitation"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="opcion.selected">{{ opcion.vcDescripcion }}
                    </label>
                  </div>
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="titleprofesional">Título profesional*</label>
                  <input formControlName="titulo" type="text" class="form-control" placeholder="Título profesional" id="titleprofesional" name="titleprofesional" autocomplete="off"
                    (keyup)="formRequest.get('titulo').setValue(formRequest.get('titulo').value.toUpperCase())" />
                    <span style="color: red;"
                    class="error-texto-govco alert-entradas-de-texto-govco" id="title-note" role="alert"
                    aria-live="assertive">
                    {{ getErrorMessage('titulo') }}
                    </span>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-5 col-md-5 col-xl-5">
                  <label for="numbertarget">Número de tarjeta profesional</label>
                  <input formControlName="tarjeta" type="text" class="form-control" placeholder="Número de tarjeta profesional"
                  id="numbertitle" name="numbertitle" autocomplete="off"
                  (keyup)="formRequest.get('tarjeta').setValue(formRequest.get('tarjeta').value.toUpperCase())" />
              </div>
          </div>
      </div>
      <div class="solicitante-juridico" *ngIf="mostrarAlgoPJ">
          <div class="form-row">
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="primerNombre">Razón social</label>
                  <input formControlName="razon" type="text" id="primerNombre" class="form-control gov-co-form-control"
                  placeholder="Razón social" readonly/>
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-4 col-md-4 col-xl-4">
                  <label for="tipoDocumento">Tipo de Identificación</label>
                  <select formControlName="tipoDocumento" class="form-control">
                    <option *ngFor="let opcion of documentTypes" [value]="opcion.idTipoIdentificacion">{{ opcion.descripcion }}</option>
                  </select>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-4 col-md-4 col-xl-4">
                  <label for="primerNombre">Nit</label>
                  <input formControlName="nit" type="text" id="primerNombre" class="form-control gov-co-form-control"
                  placeholder="Número de nit" readonly/>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-4 col-md-4 col-xl-4">
                  <label for="primerNombre">Código de validación</label>
                  <input formControlName="code" type="text" id="primerNombre" class="form-control gov-co-form-control code"
                  placeholder="Código de validación" readonly/>
              </div>
          </div>
          <div class="form-row mt-4">
              <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-12">
                  <p class="title_section">Datos del representante legal</p>
              </div>
          </div>
          <div class="form-row mt-2">
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="primerNombre">Primer Nombre </label>
                  <input formControlName="primerNombre" type="text" id="primerNombre" class="form-control gov-co-form-control"
                  placeholder="Primer nombre" readonly/>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="segundoNombre">Segundo Nombre</label>
                  <input formControlName="segundoNombre" type="text" id="segundoNombre" class="form-control gov-co-form-control"
                  placeholder="Segundo nombre" readonly/>
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="primerApellido">Primer Apellido </label>
                  <input formControlName="primerApellido" type="text" id="primerApellido" class="form-control gov-co-form-control"
                  placeholder="Primer apellido" readonly/>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="segundoApellido">Segundo Apellido</label>
                  <input formControlName="segundoApellido" type="text" id="segundoApellido"
                  class="form-control gov-co-form-control" placeholder="Segundo apellido" readonly/>
              </div>
          </div>
          <div class="form-row mt-3">
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="tipoDocumento">Tipo de Identificación</label>
                  <select formControlName="tipoDocumento" class="form-control">
                    <option *ngFor="let opcion of documentTypes" [value]="opcion.idTipoIdentificacion">{{ opcion.descripcion }} </option>
                  </select>
              </div>
              <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                  <label for="numeroDocumento">Número de Identificación </label>
                  <input formControlName="numeroDocumento" type="text" id="numeroDocumento"
                  class="form-control gov-co-form-control" placeholder="Número de documento de identidad" readonly/>
              </div>
          </div>
          <div class="form-row mt-3">
            <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                <label for="numeroContacto">Teléfono de contacto</label>
                <input formControlName="numeroContactoN" type="text" id="numeroContacto"
                class="form-control gov-co-form-control" placeholder="Teléfono de contacto" readonly/>
            </div>
            <div class="form-group col-sm-12 col-xs-12 col-lg-6 col-md-6 col-xl-6">
                <label for="correoElectronico">Correo electrónico</label>
                <input formControlName="correoElectronicoN" type="text" id="correoElectronico"
                class="form-control gov-co-form-control" placeholder="Email@example.com" readonly/>
            </div>
          </div>
            <div class="form-row mt-3">
                <div class="form-group col-sm-12 col-xs-12 col-lg-10 col-md-10 col-xl-10">
                    <label for="address">Dirección</label>
                    <input formControlName="address" type="text" id="address" class="form-control gov-co-form-control address" readonly />
                </div>
            </div>
      </div>
  </form>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-2 col-xl-3 float-right">
    <div class="custom-alert">
        <a href="https://aulavirtual.saludcapital.gov.co/sicap/" style="text-decoration: none !important;" target="_blank">
            <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                Te explicamos con tutoriales
            </div>
        </a>
        <a type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                ¿Tienes dudas?
            </div>
        </a>
        <div class="collapse" id="collapseExample">
            <div class="card card-body" id="cardi_info_contact">
              <p id="colorphone" style="margin-left: 18px;">Teléfono:(571) 3649090</p>
              <p id="colorphone" style="margin-left: 18px; margin-top: -10px;">contactenos&commat;aludcapital.gov.co</p>
            </div>
        </div>
    </div>
</div>
</div>

